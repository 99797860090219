/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
/* Garet */
@import url("https://fonts.cdnfonts.com/css/garet");

//Color
$textheaderColor: #4d3f3f;
$buttonColor: #4d3f3f;
$inputFontColor: #4d3f3f;
$CopperColor: #f2f3f7;

// Font
$textFont: "Poppins", sans-serif;
$inputFont: "Garet", cursive;
