/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
/* Garet */
@import url("https://fonts.cdnfonts.com/css/garet");
.navTitle {
  text-align: left;
  font-weight: 500;
  font-family: "Garet", cursive;
  font-size: 36px;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
  color: black;
}

.btnText {
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
}/*# sourceMappingURL=navbar.css.map */