@import "./../Variables/variables.scss";

body {
  // overflow: hidden;
}

.dashboardContainer {
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  background-color: #f2f3f7;
  // overflow: hidden;
  // justify-content: space-between;
}

.btnText {
  padding-left: 10px;
  font-family: $textFont;
}

.dashboardContent {
  width: 75vw;
  padding: 0 20px;
}

.barContainer {
  width: inherit;
  display: block;
  text-align: right;
  margin-bottom: 10px;
}

.MuiDataGrid-columnHeaders .MuiCheckbox-root {
  border-radius: 0;
  color: white;
}

.MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 14px; /* Adjust the font size as needed */
  font-family: $textFont;
  background: white;
  padding: 20px;
  // font-weight: 600;
}

.MuiDataGrid-columnHeaders {
  background-color: #354e8e;
  color: white;
  font-size: 14px; /* Adjust the font size as needed */
  font-family: $textFont;
}

.color-box {
  // width: 20px;
  height: 100%;
  // background-color: green;
  padding: 10px 1px;
  color: transparent;
}
