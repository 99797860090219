/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
/* Garet */
@import url("https://fonts.cdnfonts.com/css/garet");
.DrawContainer {
  width: 230px;
  height: 500px;
  border-radius: 18px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.btnText {
  font-family: "Poppins", sans-serif;
}/*# sourceMappingURL=drawer.css.map */